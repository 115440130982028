import { useEffect, useState, useCallback } from "react";
import { usePubNub } from "pubnub-react";
import { getUserData } from "../utils/helper/getUserId";
import {
  setAdvisorLiveStatus,
  setAdvisorRushStatus,
  setAdvisorStatus,
} from "../features/advisor/advisorSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setGetPubnubMessageInfo,
  setGetPubnubMessages,
  setIsTyingClient,
} from "../features/chat/chatSlice";
import useTypingStatus from "./useTypingStatus";
const usePubNubData = () => {
  const dispatch = useDispatch();
  const pubnub = usePubNub();
  const [channels, setChannels] = useState(null);
  const [pubnubAdvisor, setPubnubAdvisor] = useState(null);
  const [pubnubData, setPubnubData] = useState(null);
  const [credit, setCredit] = useState(null);
  const { setGetLiveChatSession } = useTypingStatus();
  const { chatHeadSelectedUser } = useSelector((state) => state.chat);

  const { advisorLoginResponse } = useSelector((state) => state.login);
  const user = getUserData(advisorLoginResponse);
  const leaveApplication = useCallback(() => {
    pubnub.removeListener(listnerAdd);
  }, [pubnub]);

  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      if (subscribedChannels) {
        setGetLiveChatSession(
          subscribedChannels[subscribedChannels?.length - 1]
        );
      }
      if (statusEvent.category === "PNConnectedCategory") {
        // Handle connected status if needed
      }
    },

    presence: (liveChat) => {
      if (liveChat?.state?.type === "user") {
        if (
          liveChat?.state?.isTyping == true ||
          liveChat?.state?.isTyping == "1"
        ) {
          dispatch(setIsTyingClient(true));
        } else {
          dispatch(setIsTyingClient(false));
        }
      }
    },
    message: (event) => {
      const message = event?.message;
      if (
        message?.message_type === "text" ||
        message?.message_type === "rush_text" ||
        message?.message_type === "photo" ||
        message?.message_type === "rush_photo" ||
        message?.message_type === "rush_audio" ||
        message?.message_type === "audio"
      ) {
        dispatch(setGetPubnubMessages(message));
      }

      if (message?.message_type === "info") {
        dispatch(setGetPubnubMessageInfo(message));
      }
      const advisorProfileData = Object.values(event?.message?.advisor);
      const matchedAdvisor = advisorProfileData.filter(
        (advisor) => advisor.id === user?.id
      );
      if (matchedAdvisor?.length > 0) {
        const advisorLiveStatus = matchedAdvisor?.[0]?.live_status;
        const advisorRushStatus = matchedAdvisor?.[0]?.rush_status;
        const advisorStatus = matchedAdvisor?.[0]?.status;
        advisorLiveStatus == "1" || advisorLiveStatus == "2"
          ? dispatch(setAdvisorLiveStatus(true))
          : dispatch(setAdvisorLiveStatus(false));
        advisorRushStatus == "1"
          ? dispatch(setAdvisorRushStatus(true))
          : dispatch(setAdvisorRushStatus(false));
        advisorStatus == "1"
          ? dispatch(setAdvisorStatus(true))
          : dispatch(setAdvisorStatus(false));
      }
    },
  };

  useEffect(() => {
    setChannels("Channel_Advisor_".concat(user?.id));
    pubnub.addListener(listnerAdd);
    if (channels) {
      pubnub.subscribe({
        channels: [channels, "channel_application"],
        withPresence: true,
      });
    }

    return leaveApplication;
  }, [
    pubnub,
    channels,
    chatHeadSelectedUser,
    advisorLoginResponse,
    listnerAdd,
  ]);

  return { pubnubAdvisor, pubnubData, credit, channels, setChannels };
};

export default usePubNubData;
